import React, { Component, Fragment } from "react";
import { graphql } from "react-apollo";
import Map from "react-map-gl";
import BackgroundSlider from "react-background-slider";

import {
  Card,
  CardMedia,
  CardContent,
  CircularProgress,
  Divider,
  Fade,
  Grid,
  Hidden,
  Paper,
  Typography,
  withMobileDialog,
} from "@material-ui/core";
import { LocationOn } from "@material-ui/icons";

import numeral from "numeral";
import { withStyles } from "@material-ui/core/styles";
import cloudinary from "cloudinary-core";
import { loader } from "graphql.macro";
import classNames from "classnames";
import withTranslator from "./hocs/withTranslator";
import "../index.css";

const projectsQuery = loader("../queries/Projects.graphql");

const cl = new cloudinary.Cloudinary({
  cloud_name: "dwelloptimal",
  secure: true,
});
const styles = (theme) => ({
  slanted: {
    backgroundColor: "red",
    position: "relative",
    padding: "10px 20px 10px 10px",
    "&:after": {
      content: "",
      display: "block",
      position: "absolute",
      // width: "100%",
      // height: "100%",
      background: "inherit",
      zIndex: "-1",
      top: 0,
      left: 0,
      transformOrigin: "bottom left",
      transform: "skew(-30deg, 0deg)",
    },
  },
});
const projects = [
  {
    name: "Salinas",
    summary:
      "Salinas é um projeto solar comunitário do 5 MW que atende pequenas e médias empresas em Minas Gerais.",
    carbonReduction: 707,
    imageUrl:
      "https://res.cloudinary.com/dwelloptimal/image/upload/v1654112336/energea/br-images/banner-9_1.jpg",
  },
  {
    name: "Iguatama",
    summary:
      "Iguatama é um projeto solar comunitário do 2.2 MW que atende pequenas e médias empresas em Minas Gerais.",
    carbonReduction: 311,
    imageUrl:
      "https://res.cloudinary.com/dwelloptimal/image/upload/v1652988659/energea/br-images/banner-3.jpg",
  },
];

class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactFormOpen: false, // eslint-disable-line
      curProject: 0,
    };
  }

  componentDidMount() {
    this.timer = setInterval(() => {
      this.setState({
        curProject: (this.state.curProject + 1) % projects.length,
      });
    }, 6000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    const { t, dark, i18n } = this.props;
    const { curProject } = this.state;
    return (
      <>
        <Grid id="projetos" container style={{ width: "100%" }}>
          <Hidden smDown>
            <div
              className="slanted"
              style={{
                width: "60%",
                height: "100vh",
                maxHeight: "800px",
                zIndex: 1000,
              }}
            >
              {/* <div style={{ height: "100vh", width: "50%" }}></div> */}
            </div>
            <Grid
              container
              justifyContent="center"
              style={{
                float: "right",
                width: "60%",
                height: "100vh",
                maxHeight: "800px",
                position: "absolute",
                right: 0,
              }}
            >
              <div
                style={{
                  background:
                    "linear-gradient(251.89deg, rgba(27, 44, 74, 0) 6.99%, #1B2C4A 98.57%)",
                  zIndex: 11,
                  height: "100%",
                  width: "100%",
                  position: "absolute",
                }}
              ></div>
              {/* <BackgroundSlider
                images={projects.map((el) => el.imageUrl)}
                duration={6}
                transition={1}
              /> */}
              {projects.map((el, index) => (
                <Grid
                  key={`project-list-${el.name}`}
                  container
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                  }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Fade timeout={1000} in={index === curProject}>
                    <figure
                      style={{
                        backgroundImage: `url(${el.imageUrl})`,
                        backgroundSize: "cover",
                        margin: 0,
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        zIndex: 10,
                      }}
                    />
                  </Fade>
                  <Grid item xs={12} md={5} lg={4} style={{ zIndex: 20 }}>
                    <Typography
                      style={{
                        color: dark ? "#fff" : null,
                        marginTop: "2rem",
                        marginBottom: "3rem",
                        width: "100%",
                      }}
                      color="primary"
                      variant="h2"
                    >
                      {i18n.t("projects", "Projetos")}
                    </Typography>
                    <Fade timeout={1000} in={index === curProject}>
                      <Grid item style={{ color: "#fff" }}>
                        <Typography variant="h3">{el.name}</Typography>
                        <Typography
                          variant="body1"
                          style={{ minHeight: "96px" }}
                        >
                          {el.summary}
                        </Typography>
                        <Grid
                          container
                          style={{ width: "100%", marginBottom: "4rem" }}
                          alignItems="center"
                          spacing={2}
                        >
                          <Grid item>
                            <Typography
                              variant="body1"
                              style={{
                                fontWeight: 700,
                                fontSize: "72px",
                                color: "rgba(255,255,255,0)",
                                "-webkit-text-stroke-width": "1px",
                                "-webkit-text-stroke-color": "#fff",
                              }}
                            >
                              {el.carbonReduction}
                            </Typography>
                          </Grid>
                          <Grid container item xs direction="column">
                            <Grid item>
                              <Typography variant="h2">tons</Typography>
                            </Grid>
                            <Grid item>
                              <Typography>
                                de emissões do CO2 evitados
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Fade>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12}>
              {" "}
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid item xs={12} style={{ background: "#fff", padding: "1rem" }}>
              <Typography
                style={{
                  marginTop: "2rem",
                  marginBottom: "3rem",
                  width: "100%",
                  // color: "red",
                }}
                color="primary"
                variant="h2"
              >
                Projetos
              </Typography>
              <Paper
                elevation={0}
                style={{
                  borderRadius: "8px",
                  overflow: "hidden",
                }}
              >
                <img
                  src="https://res.cloudinary.com/dwelloptimal/image/upload/v1654709669/energea/br-images/image_5.png"
                  style={{
                    width: "100%",
                    height: "auto",
                    marginBottom: "-8px",
                  }}
                />
              </Paper>
            </Grid>
          </Hidden>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withMobileDialog()(withTranslator(Projects))
);
